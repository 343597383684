const events = [
    {
      id: 0,
      title: "Reformer",
      start: new Date(2023, 7, 10, 7, 30, 0),
      end: new Date(2023, 7, 10, 8, 30, 0)
    },
    {
      id: 1,
      title: "Pole Dance",
      start: new Date(2023, 7, 11, 13, 30, 0),
      end: new Date(2023, 7, 11, 14, 30, 0)
    },
    {
      id: 2,
      title: "Matwork",
      start: new Date(2023, 7, 9, 18, 30, 0),
      end: new Date(2023, 7, 9, 19, 30, 0)
    }
  ];
  
  export default events;