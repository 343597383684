import React, { Component } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import 'moment/locale/it';

import "./App.css";
import events from "./events";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

export let navigate = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE',
}

const messages = {month: 'Mese', work_week: 'Settimana', week: 'Settimana', day: 'Giorno', previous: 'Precedente', next: 'Prossimo', today: 'Oggi'}

class CustomToolbar extends React.Component {
  render() {
    const handleCancel = (event) => {      
      alert("Annullato");
    };

      let { localizer: { messages }, label } = this.props
      return(
          <div className="rbc-toolbar">
              <span className="rbc-btn-group">
                  <button type="button" onClick={this.navigate.bind(null, navigate.PREVIOUS)}>Precedente</button>
                  <button type="button" onClick={this.navigate.bind(null, navigate.NEXT)}>Prossimo</button>
              </span>
              <span className="rbc-toolbar-label">{label}</span>
              <span className="rbc-btn-group">
              <button type="button" onClick={handleCancel}>Annulla</button>
              </span>
              
          </div>
      )
  }
  navigate = action => {
      this.props.onNavigate(action)
  }
}

class App extends Component {
  state = {
    events: [
      {
        start: moment().toDate(),
        end: moment()
          .add(1, "hours")
          .toDate(),
        title: "13.30-14.30"
      }
    ]
  };

  // loadCalendar() {
  //   axios
  //     .get(api + "api/calendar", {
  //       headers: {
  //         "Content-type": "application/json",
  //         Authorization: reqtoken
  //       }
  //     })

  //     .then(json => {
  //       const events = json.data.data.data.map(data =>
  //         this.setState(
  //           events({
  //             title: data.title,
  //             start: data.startDate,
  //             end: data.endDate
  //           })
  //         )
  //       );

  //     })
  //     .catch(erros => {
  //       console.log(erros);
  //     });
  // }

 
  render() {
    const handleSelected = (event) => {
      // setSelected(event);
      alert(event.title);
    };

    const today = new Date();
    return (
      <div className="App">
        <p><b>Selezionare il nuovo appuntamento o premere Annulla</b></p>
        
        <Calendar
          localizer={localizer}
          views={{ work_week:true }}
          selectable={true}
          defaultDate={new Date()}
          defaultView="work_week"
          culture = 'it'
          // events={this.state.events}
          messages = {messages}
          events={events}
          style={{ height: "100vh" }}
          onSelectEvent={handleSelected}
          startAccessor="start"
          endAccessor="end"
          min={
            new Date(
              today.getFullYear(), 
              today.getMonth(), 
              today.getDate(), 
              7, 30
            )
          }
          max={
            new Date(
              today.getFullYear(), 
              today.getMonth(), 
              today.getDate(), 
              22, 30
            )
          }
          components={{toolbar: CustomToolbar}}
        //   onSelectEvent={(data) => {
        //     alert(data);
        // }}
        // onSelectEvent={event => {
        //   let endDate = moment(event.endDate);
        //   let startDate = moment(event.startDate)
        //   openEditAppointmentModal(event, customerData, moment.duration(endDate.diff(startDate)).asMinutes())
        // }}
        />
      </div>
    );
  }
}

export default App;


